import * as React from 'react';

const PrivacyPageDE = () => {
	return (
		<>
			<title>Datenschutzinformation für die Smart Kitchen Dock App</title>
			<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
			<meta name="Generator" content="Microsoft Word 15 (filtered)" />
			<title>Blank</title>
			<style
				dangerouslySetInnerHTML={{
					__html: '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:Wingdings;\n\tpanose-1:5 0 0 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:PMingLiU;\n\tpanose-1:2 2 5 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Calibri;\n\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n@font-face\n\t{font-family:"Arial Black";\n\tpanose-1:2 11 10 4 2 1 2 2 2 4;}\n@font-face\n\t{font-family:Tahoma;\n\tpanose-1:2 11 6 4 3 5 4 4 2 4;}\n@font-face\n\t{font-family:"\\@PMingLiU";\n\tpanose-1:2 1 6 1 0 1 1 1 1 1;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tfont-size:11.0pt;\n\tfont-family:"Times New Roman",serif;}\np.MsoHeader, li.MsoHeader, div.MsoHeader\n\t{margin:0cm;\n\tfont-size:11.0pt;\n\tfont-family:"Times New Roman",serif;}\np.MsoFooter, li.MsoFooter, div.MsoFooter\n\t{mso-style-link:"Footer Char";\n\tmargin:0cm;\n\tline-height:10.0pt;\n\tfont-size:8.0pt;\n\tfont-family:"Arial",sans-serif;}\np.MsoBodyText, li.MsoBodyText, div.MsoBodyText\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:9.0pt;\n\tmargin-left:0cm;\n\tline-height:13.0pt;\n\tfont-size:11.0pt;\n\tfont-family:"Times New Roman",serif;}\na:link, span.MsoHyperlink\n\t{color:blue;\n\ttext-decoration:underline;}\nspan.FooterChar\n\t{mso-style-name:"Footer Char";\n\tmso-style-link:Footer;\n\tfont-family:"Arial",sans-serif;}\n.MsoChpDefault\n\t{font-size:11.0pt;}\n.MsoPapDefault\n\t{margin-bottom:10.0pt;\n\tline-height:115%;}\n /* Page Definitions */\n @page WordSection1\n\t{size:21.0cm 841.95pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n'
				}}
			/>
			<div className="WordSection1">
				<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Datenschutzinformation
						</span>
					</b>
				</p>
				<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							für die Smart Kitchen Dock App{' '}
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '12.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die Home Connect GmbH mit Sitz in Carl-Wery-Straße 34, 81739 München, Deutschland (nachfolgend „Home Connect“ oder „Wir“) ist die
						verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten im Zusammenhang mit der Smart Kitchen
						Dock App (nachfolgend „App“). Die Smart Kitchen Dock App ist nur mit dem Smart Kitchen Dock kompatibel, d.h. sie funktioniert nur mit
						dem Smart Kitchen Dock. Sie ist nicht interoperabel mit anderen Geräten (z.B. anderen Smart Speakern oder Docking-Stationen).
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Wir erheben, verarbeiten und nutzen personenbezogene Daten, die im Rahmen der Nutzung der App, der damit verbundenen Hausgeräte oder von
						Home Connect angebotenen Dienste entweder durch Sie eingegeben oder in sonstiger Weise entstehen und verarbeitet werden, in
						Übereinstimmung mit den anwendbaren Datenschutzgesetzen und informieren Sie nachstehend über die durch uns auf Basis des mit Ihnen
						bestehenden Nutzungsvertrages oder einer sonstigen Rechtsgrundlage vorgenommenen Datenverarbeitungen.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							1.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Kategorien von personenbezogenen Daten
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Im Zusammenhang mit der Nutzung der Smart Kitchen Dock App, dem Smart Kitchen Dock und der damit über die Home Connect Services
						verbundenen Hausgeräte oder weitere, von Home Connect angebotenen Services und Funktionen (nachstehend „Dienste“) erhebt, verarbeitet
						und nutzt Home Connect die folgenden Kategorien personenbezogener Daten:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							a.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Installationsdaten, Nutzungsdaten,{' '}
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Daten, die wir im Zusammenhang mit dem Verwenden von Benutzerkonten und der Einrichtung der App erheben und verwenden sind:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Verwendung von Standortdaten des Endgerätes für die Einrichtung und den Betrieb
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Im Rahmen der Einrichtung des Smart Kitchen Docks und der App verwenden wir die Standortdaten, um die Bluetooth Low Energy (BLE) Signale
						zu scannen, die für die Einrichtung und die Verbindung des Docks und der App mit dem verwendeten End-Gerät des Benutzers benötigt
						werden. Dies dient neben dem initialen Herstellen der Verbindung auch der Funktionalität, bei der das Gerät durch den Nutzer in das
						Smart Kitchen Dock legt um dadurch den automatischen Start der Smart Kitchen Dock App zu veranlassen. Diese Daten werden nur zur
						unmittelbaren Verwendung innerhalb der Anwendungen verwendet und nicht innerhalb der Smart Kitchen Dock App oder des Smart Kitchen Docks
						gespeichert und auch nicht an Systeme außerhalb dieser Anwendungen übermittelt.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Verwendung von Standortdaten aus dem Netzwerk (während der Einrichtung)
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die Übermittlung von Standortdaten aus dem von Ihnen verbundenen Netzwerk werden benötigt, um die korrekte Ortszeit auf der Smart
						Kitchen Dock App Anwendung anzuzeigen und Ihnen die für Ihren Standort freigegebenen Inhalte (z.B. Trending Topics) zur Anzeige im Home
						Screen der Smart Kitchen Dock App anzuzeigen sowie die korrekten Home Connect Kundendienst-Kontaktdaten je nach Land des Benutzers
						anzuzeigen.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Diese Netzwerk-IP-Ortung wird einmalig während jeder Einrichtung (z.B. erneut nach einem Factory Reset) innerhalb Smart Kitchen Dock-App
						durchgeführt. und verwendet dazu einmalig die IP Adresse im Rahmen einer Datenübertragung an einen Dienstleister um den Ländercode
						speichern zu können.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Angaben, die Sie im Rahmen der Verbindung mit dem Home Connect als auch ein Amazon Alexa Benutzerkonto angeben, wobei diese jedoch nicht
						im Gerät, sondern über einen vom jeweiligen Anbieter übermittelten Token gespeichert werden (für diese Benutzerkonten gelten jeweils die
						gesonderten Nutzungsbedingungen und Datenschutzinformationen der jeweiligen Anbieter).
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '35.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Informationen, die wir im Zusammenhang mit der laufenden Nutzung der App erheben und temporär verwenden:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '71.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Standortdaten (siehe vorstehende Beschreibung)
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '71.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Gestenerkennung mittels Bewegungssensor &nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '35.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Informationen, die wir im Zusammenhang mit der laufenden Nutzung der App erheben und speichern:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '71.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Einstellungen des Benutzerkontos{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Identifikator des Mobilgeräts
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '71.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						App Einstellungen sowie Status der Tracking-Einstellung{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Interaktion mit Trending Topics („Smart Kitchen Dock Inspiration“) und „Smart Kitchen Dock Navigation“ (z.B. Nutzungshäufigkeit)
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '71.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Interaktion mit den Funktionalitäten der App (z.B. Nutzung welcher Seiten/Funktionen in statistischer Hinsicht).
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '53.85pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							b.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Verbundene Services, Nutzung gemeinsam mit Dritten
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Haben Sie das Smart Kitchen Dock einmal über die Smart Kitchen Dock App auf dem mobilen Endgerät eingerichtet und mit Ihrem Home Connect
						Account beziehungsweise Ihrem Alexa Account verbunden („verbundene Services“), werden die Funktionalitäten des Smart Kitchen Docks (z.B.
						Sprachsteuerung) über eine Internetverbindung des Smart Kitchen Docks über das lokale Wi-Fi-Netzwerk bereitgestellt. Dies erfolgt unter
						Verwendung der Geräte-Daten sowie der, über Alexa verarbeiteten Befehle. Die Steuerung erfolgt dabei zunächst über die App, es werden
						dabei jedoch nicht direkt in der App verarbeitet, sondern dies erfolgt jeweils beim verbundenen Service. Bitte beachten Sie daher
						ergänzend die jeweilige Datenschutzinformation der verbundenen Services.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Haben Sie sich einmal mit ihrem Amazon Alexa und Ihrem Home Connect Benutzerkonto angemeldet, bleibt der Smart Kitchen Dock solange mit
						Ihrem Amazon Alexa und Ihrem Home Connect Benutzerkonto verbunden, bis sie diese Verbindung wieder aktiv über die Smart Kitchen Dock App
						trennen oder Sie den Smart Kitchen Dock auf Werkseinstellungen zurücksetzen. Durch Entfernen des mobilen Endgeräts wird die Verbindung
						zu Ihrem Amazon Alexa und Ihrem Home Connect Benutzerkonto nicht getrennt. Bitte beachten Sie für weitere Informationen die Hinweise in
						den Nutzungsbedingungen.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Wenn Sie Dritte den Smart Kitchen Dock benutzen lassen, verpflichten Sie sich, diese Dritten vor der Nutzung über Zweck und Umfang der
						Datenerhebung und die weitere Verarbeitung und Nutzung durch den Betreiber sowie Amazon zu informieren und die Einwilligung aller
						Benutzer in diesen Datenumgang einzuholen.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							c.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								App-Nutzungsdaten
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						App-Nutzungsdaten sind Daten aus Ihrer Interaktion mit der App wie z.B. verwendete Funktionalitäten der App, Klickverhalten in Bezug auf
						Bedienelemente der App, Auswahl in Dropdown-Menüs, Einstellungen von On/Off-Schaltern sowie Störungsberichte der App (falls Funktion
						vorhanden). Siehe dazu auch unten{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						6
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						.{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							2.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Verwendungszwecke
						</span>
					</b>
				</p>
				<p className="MsoBodyText" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die genannten Datenkategorien nutzen wir{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						zur Bereitstellung der Funktionalitäten der App sowie der über die App angebotenen Dienste (1.a.-c.),
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						zur Verbesserung der Benutzerfreundlichkeit der App und zur spezifischen oder allgemeinen Störungsbeseitigung in der App und den darüber
						angebotenen, digitalen Diensten (1. a bis c.),
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						zur Verbesserung des Produkt- und Dienstleistungsangebots des Betreibers und der mit dem Betreiber verbundenen Unternehmen, insbesondere
						bezogen auf das Smart Kitchen Dock selbst sowie im Hinblick auf nicht genutzte Funktionen und sonstige Funktionen der App und
						verbundener Hausgeräte (einschließlich algorithmisches Lernen) (1. a bis c.),
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Kontaktaufnahme im Falle von Sicherheitshinweisen und Produktrückrufen über die Smart Kitchen Dock App (einschließlich der
						Bereitstellung von in-App/E-Mail Nachrichten) (1.a.-c.),
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						und
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '35.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						zu Marketing- und Marktforschungszwecken (einschließlich der Bereitstellung von in-App/E-Mail Nachrichten) (1.a.-c.), soweit und solange
						eine entsprechende Einwilligung vorliegt.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die Rechtsgrundlagen zu den jeweiligen Verarbeitungszwecken im Geltungsbereich der DSGVO (VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN
						PARLAMENTS UND DES RATES vom 27. April 2016) finden sich in Ziffer 12 dieser Informationen.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							3.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Regelspeicherfristen
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Vorbehaltlich abweichender gesetzlicher Vorgaben gelten die nachfolgenden Regelspeicherfristen:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '17.85pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							a.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Standortdaten:
							</span>
						</u>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						keine dauerhafte Speicherung innerhalb oder außerhalb der Anwendung, die Verwendung erfolgt nur zur Ansteuerung von konkreten Aktionen
						wie in 1 a. oben beschrieben.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '17.85pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							b.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Nutzungsdaten:
							</span>
						</u>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						Löschung mit Löschung des lokalen Benutzerkontos oder des der verbundenen Nutzer-Accounts.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '17.85pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							c.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								App-Nutzungsdaten:
							</span>
						</u>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						Speicherung in pseudonymer Form und Bereitstellung in personalisierter Form für über die App erbrachte Dienste/Mitteilungen soweit die
						Funktion „Tracking von Benutzungsdaten erlauben“ aktiviert ist. Mit Deaktivierung der Funktion wird die zum Tracking verwendete
						individuelle ID zurückgesetzt, so dass bereits erhobene App-Nutzungsdaten Ihren nicht mehr zugeordnet werden können.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							4.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Steuerung der Datenverarbeitung
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							a.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Konnektivität Ihres Hausgerätes
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Über die App können Sie die Konnektivität Ihres Hausgerätes steuern. Zur Nutzung der durch das Smart Kitchen Dock und die Smart Kitchen
						Dock App zur Verfügung gestellten Funktionen werden Informationen (z.B. Ihre Benutzerkennungen; gestellte Anfragen und Ihre
						Steuerungsanweisungen für Hausgeräte) über eine Datenverbindung an Server übermittelt, die zur Bearbeitung der Anfragen („Amazon
						Server“) sowie der Ausführung Ihrer Steuerungsanweisungen dienen („Home Connect Server“).
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.15pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							b.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Benutzerkonten und lokale App-Daten
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Über die App können Sie Ihre Benutzerkonten steuern und lokal vorgehaltene App-Daten löschen.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie können den Smart Kitchen Dock in den Werkszustand zurücksetzen. In diesem Fall wird die Verknüpfung des Smart Kitchen Docks zu Ihrer
						Smart Kitchen Dock App und Ihrem Alexa und Home Connect Benutzerkonto aufgelöst.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '35.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Durch das Löschen der App entfernen Sie alle lokal gespeicherten nutzerbezogenen Daten, nicht aber die angelegten Accounts.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							5.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Übermittlung oder Weitergabe Ihrer Daten z.B. an Dritte
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Zur Bereitstellung der App und der darüber angebotenen Dienste arbeiten wir mit verschiedenen Dienstleistern zusammen. Soweit wir diese
						Dienstleister zur streng weisungsgebundenen Datenverarbeitung als Auftragsverarbeiter verpflichtet haben, bedarf eine Datenverarbeitung
						durch diese Dienstleister nicht Ihrer Einwilligung.{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Dienstleister die wir als Auftragsverarbeiter im Einsatz haben:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '53.25pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-35.25pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						•
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Dienstleister für Hosting-Services (u.a. für die Speicherung)
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '53.25pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-35.25pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						•
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Dienstleister für Programmierleistungen
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '53.25pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-35.25pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						•
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Dienstleister für Hotline-Servicesund sonstige App Dienste.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						An andere Empfänger übermitteln wir Ihre Daten nur, soweit dies zur Erfüllung eines Vertrags mit Ihnen oder zwischen Ihnen und dem
						Drittanbieter erforderlich ist, wir oder der Empfänger ein berechtigtes Interesse an der Weitergabe Ihrer Daten hat oder Ihre
						Einwilligung in die Weitergabe vorliegt. Zu diesen Empfängern zählen Dienstleister und auch andere Unternehmen der mit dem Betreiber
						verbundenen Konzernunternehmen. Darüber hinaus können Daten an andere Empfänger übermittelt werden, soweit wir aufgrund gesetzlicher
						Bestimmungen oder durch vollstreckbare behördliche bzw. gerichtliche Anordnung hierzu verpflichtet sind.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Zu den anderen Empfängern Ihrer Daten zählen insbesondere auch Diensteanbieter, deren Angebote und Leistungen (Drittdienste) Smart
						Kitchen Dock im Rahmen der App vermittelt oder die Zugangsmöglichkeit zu solchen Diensten ermöglicht (siehe dazu unten 9.).
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Inhalte, insbesondere Rezepte, die Sie selbst erstellt haben, können im Rahmen der technischen Verarbeitung und Bereitstellung auch an
						andere BSH Gesellschaften (auch international) übermittelt werden. Soweit wir eine solche Übermittlung/Verarbeitung vornehmen, erfolgt
						dies auf Grundlage datenschutzrechtlicher Vereinbarungen zwischen den beteiligten BSH Gesellschaften, die auch die Anwendung von
						Standardvertragsklauseln umfassen, soweit es sich um eine Übertragung außerhalb des Geltungsbereiches der DSGVO handelt und kein Fall
						des Art. 45 DSGVO vorliegt.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<a name="_Ref457484543">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								6.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Erfassung der App-Nutzung
							</span>
						</b>
					</a>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die App bietet die Möglichkeit zur Erfassung von App-Nutzungsdaten (siehe oben 1.d.) und setzt dazu die folgenden Anbieter ein:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '1.0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-1.0cm'
					}}
				>
					<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
					</span>
					<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Adobe Analytics von Adobe Systems Software Ireland Limited, 4–6 Riverwalk, Citywest Business Campus, Dublin 24, Republic of Ireland
						(nachfolgend „Adobe“).
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Soweit die Funktion „Tracking von Benutzungsdaten erlauben“ aktiviert ist, werden App-Nutzungsdaten an in der EU belegene Server der
						genannten Anbieter gesendet und gespeichert. Die App-Nutzungsdaten ermöglichen eine Analyse der Benutzung der App durch Sie und
						Bereitstellung von Mitteilungen für über die App erbrachte Dienste (siehe oben 1.c.). Für diese App wurde die IP-Anonymisierung
						aktiviert, so dass die von Ihnen verwendete IP-Adresse zuvor gekürzt wird. Im Auftrag von Smart Kitchen Dock wird der jeweilige Anbieter
						diese Informationen in unserem Auftrag benutzen, um Ihre Nutzung der App auszuwerten, Fehlerberichte zu generieren und um Reports über
						die App-Aktivitäten für Smart Kitchen Dock zusammenzustellen. Die im Rahmen der vorgenannten Dienste von Ihrem mobilen Endgerät
						übermittelte IP-Adresse und andere personenbezogenen Daten werden ohne Ihr gesondertes Einverständnis nicht mit anderen Daten der
						genannten Anbieter oder von Smart Kitchen Dock zusammengeführt.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie können die Erfassung von App-Nutzungsdaten (inkl. Ihrer IP-Adresse) durch vorgenannte Dienste sowie die Verarbeitung dieser Daten
						durch vorgenannte Dienste steuern, indem Sie die Funktion „Tracking von Benutzungsdaten erlauben“ aktivieren oder deaktivieren. Je nach
						Rechtslage in Ihrem Land kann es sein, dass die Funktion „App Nutzungsdaten zur Serviceverbesserung anonym senden“ standardmäßig aktiv
						ist.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Darüber hinaus können – soweit Sie dem zustimmen – Störungsberichte aus der App an uns gesendet werden, damit wir konkrete Maßnahmen
						ergreifen können, um solche Störungen zu beseitigen.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt',
						pageBreakAfter: 'avoid'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							7.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Datensicherheit
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Um Ihre Daten bspw. vor Manipulationen, Verlust und unbefugtem Zugriff durch Dritte zu schützen, setzen wir technische und
						organisatorische Maßnahmen ein. Zu diesen Maßnahmen gehört u.a. der Einsatz von Verschlüsselungstechnologie,{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Zertifikaten, der Einsatz einer Firewall am Smart Kitchen Dock Server und der Passwortschutz der Smart Kitchen Dock App.
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Unsere Sicherheitsmaßnahmen überprüfen und verbessern wir fortlaufend entsprechend dem technologischen Fortschritt.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							8.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Geltungsbereich der Informationen zum Datenschutz
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Diese Informationen zum Datenschutz gelten für die von Smart Kitchen Dock über die App angebotenen Dienste. Soweit innerhalb der App
						zusätzliche Funktionen, Leistungen oder Dienste von Smart Kitchen Dock oder einem BSH Hausgeräte Konzernunternehmen angeboten werden,
						werden dafür besondere Informationen zum Datenschutz bereitgestellt, soweit deren Nutzung datenschutzrechtlichen Besonderheiten
						unterliegt.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Diese Informationen zum Datenschutz gelten nicht für Drittdienste, auch wenn Smart Kitchen Dock die Nutzung oder den Zugang zu diesen
						Drittdienste im Rahmen der App vermittelt (zu Drittdiensten siehe auch die Nutzungsbedingungen). In diesem Fall gelten für die Nutzung
						dieser Drittdienste die jeweiligen Datenschutzbestimmungen des Diensteanbieters und ggf. zusätzliche Informationen zum Datenschutz
						unsererseits, die auf die Besonderheiten dieser Drittdienste eingehen und nur insoweit relevant sind.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Im Falle der Weitervermittlung an einen anderen Diensteanbieter unternimmt Smart Kitchen Dock zumutbare und angemessene Anstrengungen
						die Tatsache der Weitervermittlung kenntlich zu machen (z.B. bei Einbettung der Inhalte des Diensteanbieters innerhalb der App durch
						Inlineframes), soweit die Weitervermittlung nicht offenkundig ist. Offenkundigkeit ist z.B. gegeben, wenn über eine Verlinkung die Smart
						Kitchen Dock App verlassen wird und die App oder Website eines anderen Diensteanbieters aufgerufen wird.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Soweit Sie sich als Nutzer im Anwendungsbereich der DSGVO befinden, beachten Sie bitte, dass die Nutzung von Drittdiensten dazu führen
						kann, dass Ihre personenbezogenen Daten in Staaten außerhalb des Anwendungsbereichs der Datenschutz-Grundverordnung verarbeitet werden
						können. Dazu beachten Sie bitte die Datenschutzinformation des jeweiligen Drittdienstanbieters.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							9.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;Änderung der Informationen zum Datenschutz
						</span>
					</b>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Im Zuge der Weiterentwicklung der App – unter anderem bedingt durch die Implementierung neuer Technologien oder die Einführung neuer
						Dienste – kann es erforderlich werden, diese Informationen zum Datenschutz anzupassen. Smart Kitchen Dock ist berechtigt, Änderungen an
						den Informationen zum Datenschutz sowie an den besonderen Informationen zum Datenschutz für weitere Dienste des Betreibers unter den
						folgenden Voraussetzungen für die Zukunft vorzunehmen:
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Änderungen an den jeweiligen Informationen zum Datenschutz, welche sich auf die Vertragsbeziehung auswirken, werden wir Ihnen in der
						Regel mit einem Vorlauf von nicht weniger als sechs Wochen vor dem Zeitpunkt des Wirksamwerdens der Änderung über die Smart Kitchen Dock
						App und/oder per E-Mail mitteilen. Die Mitteilung wird auch Informationen über Ihr Recht, die Änderungen abzulehnen und die sich daraus
						ergebenden Konsequenzen enthalten. Sofern Sie der Änderung nicht innerhalb der in der Ankündigung genannten Frist (regelmäßig 6 Wochen)
						widersprechen, gilt dies als Einverständnis mit der Änderung ("Zustimmungsfiktion"); hierauf werden wir in der Ankündigung gesondert
						hinweisen. Die Zustimmungsfiktion gilt nicht für eine Veränderung, welche eine Zweckänderung der von Ihnen übermittelten
						personenbezogenen Daten betrifft, sofern die Interessen oder Grundrechte und Grundfreiheiten von Ihnen als der betroffenen Person, die
						den Schutz personenbezogener Daten erfordern, überwiegen und die Änderung nicht zur Wahrung der berechtigten Interessen des
						Verantwortlichen oder eines Dritten erforderlich ist.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						sofern dadurch ein ungünstiges Missverhältnis zwischen Leistung und Gegenleistung zu Ihren Lasten entstehen würde.{' '}
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Im Falle eines Widerspruchs wird der Nutzungsvertrag zu den bisherigen Bedingungen fortgesetzt. Der Betreiber ist aber berechtigt, Ihren
						Nutzungsvertrag mit dem Zeitpunkt des Wirksamwerdens der Änderung zu beenden.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Redaktionelle Änderungen dieser Nutzungsbedingungen, d.h. Änderungen, die das Vertragsverhältnis nicht betreffen, wie z.B. die Korrektur
						von Tippfehlern, werden ohne Benachrichtigung vorgenommen.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							10.
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							{' '}
							Rechte und Kontaktinformationen
						</span>
					</b>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						Sollten trotz unserer Bemühungen um Datenrichtigkeit und Aktualität falsche Informationen zu Ihrer Person gespeichert sein, werden wir
						diese auf Ihre Aufforderung hin berichtigen. Haben Sie uns Ihre Einwilligung zur Erhebung, Verarbeitung und Nutzung Ihrer
						personenbezogenen Daten erteilt, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen. Den Widerruf können Sie in der Regel
						durch die Betätigung der entsprechenden Einstellung innerhalb der App, im Übrigen durch die Nutzung der in der App genannten
						Kontaktmöglichkeiten ausüben.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						Die Löschung Ihrer personenbezogenen Daten erfolgt, wenn Sie die Einwilligung zur Speicherung widerrufen, wenn die Kenntnis der
						personenbezogenen Daten zur Erfüllung des mit der Speicherung verfolgten Zwecks nicht mehr erforderlich ist oder wenn die Speicherung
						aus sonstigen gesetzlichen Gründen unzulässig ist. Bitte berücksichtigen Sie, dass es aus technischen oder organisatorischen Gründen zu
						einer Überschneidung zwischen Ihrem Widerruf und der Nutzung Ihrer Daten, z.B. im Rahmen eines bereits versendeten Newsletters, kommen
						kann. Daten, die für Abrechnungs- und Buchhaltungszwecke erforderlich sind oder der gesetzlichen Aufbewahrungspflicht unterliegen, sind
						davon nicht berührt.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span lang="DE">&nbsp;</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sollten Sie Fragen zum Datenschutz haben oder Ihre datenschutzrechtlichen Rechte auf Widerruf der Einwilligung, Auskunft, Berichtigung,
						Löschung oder Sperrung ausüben wollen, kontaktierten Sie uns über die in der App hinterlegten Kontaktinformationen.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							11.
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Ergänzende Informationen nach der DSGVO{' '}
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							a)
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Rechtsgrundlagen für Datenverarbeitungen
							</span>
						</u>
					</b>
				</p>
				<p className="MsoBodyText" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die nachfolgenden Datenverarbeitungen stützen wir auf{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						eine gesetzliche Erlaubnis nach Art. 6 Abs. 1 lit. b DSGVO:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Bereitstellung der Funktionalitäten der App sowie der über die App angebotenen Dienste durch Verarbeitung der Datenkategorien 1.a.-c.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Übermittlung der Datenkategorien nach 1.a.-c. an Diensteanbieter von Drittdiensten, die Smart Kitchen Dock im Rahmen der App vermittelt
						(siehe dazu unten 9.).
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Beseitigung von Störungen durch Verarbeitung der Datenkategorien 1.b.-d.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						eine gesetzliche Erlaubnis nach Art. 6 Abs. 1 lit. f DSGVO:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Verbesserung der Benutzerfreundlichkeit der App durch Verarbeitung der Datenkategorien 1.d. (gilt für solche Jurisdiktionen, die die
						Erfassung von App-Nutzungsdaten auf Basis berechtigter Interessen erlauben. Die „Tracking von Benutzungsdaten erlauben“-Funktion ist in
						diesem Fall in der Grundeinstellung aktiv.)
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Verbesserung unseres Produkt- und Dienstleistungsangebots, insbesondere im Hinblick auf nicht genutzte Programme bzw. häufig genutzte
						Programme und sonstige Funktionen der App und des Hausgerätes durch Verarbeitung der Datenkategorien 1.c. und d.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Ihre Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Direktmarketing durch Verarbeitung der der Datenkategorien 1.a.-c.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Verbesserung der Benutzerfreundlichkeit der App durch Verarbeitung der Datenkategorien 1.d (gilt für solche Jurisdiktionen, die die
						Erfassung von App-Nutzungsdaten nur auf Basis einer Einwilligung erlauben. Die „Tracking von Benutzungsdaten erlauben“-Funktion wird nur
						mit Zustimmung des Nutzers aktiviert.)
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						eine gesetzliche Erlaubnis nach Art. 6 Abs. 1 lit. c und d DSGVO:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Benachrichtigung im Falle von Sicherheitshinweisen und/oder Rückrufinformationen im Rahmen der Produktbeobachtung durch Verarbeitung der
						Datenkategorien 1.a.-c.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							b)
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Ihre Rechte
							</span>
						</u>
					</b>
				</p>
				<p className="MsoBodyText" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Ergänzend zu Ziffer 11 stehen Ihnen nachfolgende Rechte zu. Um Ihr Recht geltend zu machen, nutzen Sie bitte die am Ende dieser
						Informationen zum Datenschutz angegebenen Kontaktdaten.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Ihr Recht auf Auskunft über Ihre Daten:
						</span>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						Auf Anforderung hin erteilen wir Ihnen Auskunft über die bei uns gespeicherten Daten zu Ihrer Person.{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Ihr Recht auf Berichtigung bzw. Vervollständigung Ihrer Daten:{' '}
						</span>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Unrichtige Angaben zu Ihrer Person berichtigen wir, wenn Sie uns einen entsprechenden Hinweis erteilen. Unvollständige Daten
						vervollständigen wir auf Ihren Hinweis hin, wenn dies für die zweckgemäße Verarbeitung Ihrer Daten erforderlich ist.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Ihr Recht auf Löschung Ihrer Daten:{' '}
						</span>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Bei uns zu Ihrer Person gespeicherte Daten löschen wir auf Anfrage. Für manche Daten findet die Löschung allerdings erst nach einer
						festgelegten Aufbewahrungsfrist statt, weil uns z.B. das Gesetz in manchen Fällen zur Aufbewahrung der Daten verpflichtet oder wir die
						Daten für die Abwicklung des Vertragsverhältnisses mit Ihnen benötigen.{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Beachten Sie hierzu auch Ziffer 11.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Ihr Recht auf Sperrung Ihrer Daten:{' '}
						</span>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In bestimmten gesetzlich festgelegten Fällen sperren wir Ihre Daten, wenn Sie dies wünschen. Gesperrte Daten werden nur noch in sehr
						beschränktem Umfang verarbeitet.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Ihr Widerrufsrecht bei einer Einwilligung:{' '}
						</span>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie können eine einmal erteilte Einwilligung in die Verarbeitung Ihrer Daten jederzeit mit Wirkung für die Zukunft widerrufen. Die
						Rechtmäßigkeit der Verarbeitung Ihrer Daten bis zum Widerruf bleibt hiervon unberührt.{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Beachten Sie hierzu auch Ziffer 11.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Ihr Widerspruchsrecht bei der Verarbeitung Ihrer Daten:{' '}
						</span>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie können der Verarbeitung Ihrer Daten jederzeit mit Wirkung für die Zukunft widersprechen, wenn wir Ihre Daten auf einer der
						gesetzlichen Grundlagen nach Art. 6 Abs. 1 lit. e oder f DSGVO verarbeiten. Bei einem Widerspruch stellen wir die Verarbeitung Ihrer
						Daten ein, wenn nicht zwingende schutzwürdige Gründe für die Weiterverarbeitung bestehen. Zwingende schutzwürdige Gründe bestehen für
						uns nie, wenn wir Ihre Daten zum Zwecke des Direktmarketings verarbeiten.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Ihr Recht auf Datenübertragbarkeit:
						</span>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						Auf Ihren Wunsch hin, können wir Ihnen bestimmte Daten in einem strukturierten, gängigen und maschinenlesbaren Format zur Verfügung
						stellen.&nbsp;{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Ihr Beschwerderecht bei der Aufsichtsbehörde:{' '}
						</span>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Beschwerden datenschutzrechtlicher Art können Sie auch bei einer Datenschutzbehörde einreichen. Wenden Sie sich hierzu an die
						Datenschutzbehörde, die für Ihren Wohnort zuständig ist oder nachfolgend an die für uns zuständige Datenschutzbehörde.&nbsp;{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Bayerisches Landesamt für Datenschutzaufsicht,{' '}
					</span>
					<span lang="EN-US">
						<a href="http://www.baylda.de">
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								www.baylda.de
							</span>
						</a>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							c)
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Weitergabe personenbezogener Daten an Empfänger außerhalb des EWR
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Falls für die Bereitstellung der Funktionalitäten der App sowie der über die App angebotenen Dienste erforderlich, geben wir
						personenbezogene Daten auch an Empfänger, die ihren Sitz außerhalb des EWR in sogenannten Drittstaaten haben. In diesem Fall stellen wir
						vor der Weitergabe sicher, dass beim Empfänger entweder ein angemessenes Datenschutzniveau besteht (z.B. aufgrund einer
						Angemessenheitsentscheidung der EU Kommission für das jeweilige Land oder die Vereinbarung sogenannter EU-Standardvertragsklauseln der
						Europäischen Union mit dem Empfänger) oder Ihre Einwilligung in die Weitergabe vorliegt.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie können bei uns eine Übersicht über die Empfänger in Drittländern und eine Kopie der konkret vereinbarten Regelungen zur
						Sicherstellung des angemessenen Datenschutzniveaus erhalten. Nutzen Sie hierzu die am Ende dieser Informationen zum Datenschutz
						angegebenen Kontaktdaten.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '17.85pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Beachten Sie hierzu auch Ziffer 9 für die Weitergabe personenbezogener Daten bei der Nutzung von Drittdiensten.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							d)
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Kontaktdaten des Datenschutzbeauftragten
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						In allen Fragen zum Thema Datenschutz und zur Wahrnehmung Ihrer Rechte, können Sie sich über folgende Kontaktdaten direkt an unseren
						Datenschutzbeauftragten wenden:
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						BSH Hausgeräte GmbH
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						Datenschutzbeauftragter
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						Carl-Wery-Str. 34
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						81739 München
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '18.0pt' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '18.0pt' }}>
					<span lang="EN-US">
						<a href="mailto:Data-Protection-DE@bshg.com">
							<span
								lang="DE"
								style={{
									fontSize: '10.0pt',
									fontFamily: '"Arial",sans-serif',
									textDecoration: 'none'
								}}
							>
								Data-Protection-DE@bshg.com
							</span>
						</a>
					</span>
				</p>
				<p className="MsoBodyText" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;
						</span>
					</b>
				</p>
				<p className="MsoNormal" align="right" style={{ marginBottom: '10.0pt', textAlign: 'right', lineHeight: '115%' }}>
					<span
						lang="EN-US"
						style={{
							fontSize: '10.0pt',
							lineHeight: '115%',
							fontFamily: '"Arial",sans-serif'
						}}
					>
						Version: V1.0.0 September 2022
					</span>
				</p>
			</div>
		</>
	);
};

export default PrivacyPageDE;
